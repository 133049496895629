import {
  selectContentDataForEventTracking,
  selectContentPricesByContentId,
  selectContentPropByContentId,
  selectExternalLinkPropByContentId,
} from '@store/contents-slice/_selectors'
import { toAbsoluteUrl } from '../../../_utilities/to-absolute-url'
import { useAppDispatch, useAppSelector as useSel } from '@store/index'
import { useRouter } from 'next/router'
import type { CheckoutVideoFlowState } from '../../../_hooks/use-checkout-video-flow-state'
import type { ContentType } from '@apis/contents-microservice/_entities/_types'
import { changeDialogParams } from '@components/dialog-provider/reducer/change-dialog-params'
import { setAudioPlay } from '@store/player-slice'
import { isEqualToOne } from '@root/utils/connascence'
import { DEFAULT_AUTOPLAY_QUERY } from '@shared/constants/root.constants'
import { TrackingEventsEnum } from '@shared/enums/tracking-events.enum'
import { trackEvent } from '@utils/event-tracking'
import { useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'

interface Props {
  buttonState: CheckoutVideoFlowState
  contentId: string
  channelPathname: string
}

export function useToVideoInfoButtonHandleNext({
  buttonState,
  contentId,
  channelPathname,
}: Props): () => void {
  const router = useRouter()
  const appDispatch = useAppDispatch()
  const contentType = useSel(selectContentPropByContentId(contentId)('contentType')) ?? 'live'
  const contentPrices = useSel(selectContentPricesByContentId(contentId))
  const firstBundleContent = useSel(selectContentPropByContentId(contentId)('bundle'))
    ?.bundleContents?.[0]?.content

  const externalLink = useSel(selectExternalLinkPropByContentId(contentId)('externalLinkUrl')) ?? ''

  // Get the price data for the facebook event data
  const eventPriceData = useSel(selectContentDataForEventTracking(contentId))

  function goToVideoInfoPage(): void {
    const videoInfoPagePathname = `/${channelPathname}/${contentId}`
    router?.push(videoInfoPagePathname)
  }

  // If user clicking buy or book from primary rail open modal straight away if there is a single price
  // if multi price do not open modal as user needs to select there price
  function checkOut(): void {
    if (contentPrices && isEqualToOne(contentPrices.length)) {
      trackEvent(TrackingEventsEnum.ADD_TO_CART, eventPriceData)
      changeDialogParams(null, {
        dialogType: 'checkout',
        channelPathname,
        contentId,
        url: `${window.location.origin}/${channelPathname}/${contentId}`,
      })
    }
    goToVideoInfoPage()
  }

  let watchPlayerPath = `/${channelPathname}/${contentId}/watch?${DEFAULT_AUTOPLAY_QUERY}`

  const isVideoPlayInPlaceActive = useSelector(
    selectAppFeatureFlagStatusByName('flagVideoPlayInPlace'),
  )
  if (isVideoPlayInPlaceActive) {
    watchPlayerPath = `/${channelPathname}/${contentId}?${DEFAULT_AUTOPLAY_QUERY}`
  }

  const playerPathnameMap: Record<ContentType, string> = {
    live: watchPlayerPath,
    vod: watchPlayerPath,
    'external-link': '',
    bundle: '',
    audio: watchPlayerPath,
    'video-review': '',
  }

  const externalLinkPathnameMap: Record<ContentType, string> = {
    live: '',
    vod: '',
    'external-link': toAbsoluteUrl(externalLink),
    bundle: toAbsoluteUrl(firstBundleContent?.externalLink?.externalLinkUrl ?? ''),
    audio: ',',
    'video-review': '',
  }

  function play(): void {
    router?.push(playerPathnameMap[contentType], undefined, { shallow: true })
  }

  function join(): void {
    router?.push(externalLinkPathnameMap[contentType])
  }

  function listen(): void {
    if (isVideoPlayInPlaceActive) {
      router?.push(playerPathnameMap[contentType], undefined, { shallow: true })
    } else {
      appDispatch(setAudioPlay(true))
    }
  }

  return function next(): void {
    switch (buttonState) {
      case 'ticketedOnly':
      case 'accessRestricted':
      case 'findOutMore':
      case 'subscribe':
        goToVideoInfoPage()
        break
      case 'buyNow':
      case 'bookNow':
        checkOut()
        break
      case 'purchased':
        break
      case 'booked':
        break
      case 'subscribed':
        break
      case 'watchNow':
        play()
        break
      case 'joinNow':
        join()
        break
      case 'listenNow':
        listen()
        break
      case 'expired':
        break
      case 'soldOut':
        break
      case 'loading':
        break
      default:
        break
    }
  }
}
